.section_maldives {
  padding: 45px 40px 0px 45px;
}

.section_maldives img {
  max-height: 250px;
  min-height: 250px;
  object-fit: cover;
  border-radius: 6px;
  filter: brightness(0.6);
}

.section_maldives .card_info {
  position: relative;
  height: 220px;
}

.section_maldives .card_info img {
  max-height: 160px;
  min-height: 100%;
}

.section_maldives .card1_info {
  position: relative;
}

.section_maldives .card1_info img {
  max-height: 470px;
  min-height: 100%;
}

.section_maldives .card1_info p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 20px;
  margin: 0px;
  font-weight: 500;
}

.section_maldives .card_info p {
  position: absolute;
  bottom: 3%;
  left: 5%;
  color: #fff;
  font-size: 16px;
  margin: 0px;
  font-weight: 500;
}

@media screen and (max-width:767px) {
  .section_maldives {
    padding: 40px 0px 0px 0px;
  }

}